import React, { useState, useRef } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
const App = () => {
  const [name, setName] = useState("");
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown when an option is selected
  };
  return (
    <div dir="rtl">
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-12 col-md-7 mb-3 mb-md-0">
            <div className="container mt-5 pe-3">
              <h1>سجل اهتمامك</h1>
              <form className="mt-5 form">
                <div className="input-container mb-5">
                  <label htmlFor="name" className="top-label">
                    الاسم
                  </label>
                  <input
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    id="name"
                    type="text"
                    className="top-label-input"
                  />
                </div>

                <div className="input-container mb-5">
                  <label htmlFor="phone" className="top-label">
                    رقم الهاتف
                  </label>
                  <IntlTelInput
                    containerClassName="intl-tel-input top-label-input"
                    inputClassName="phone"
                    placeholder=""
                    defaultCountry="sa"
                  />
                </div>

                <div class="input-container mb-5">
                  <label for="q" class="top-label">
                    هل انت مستحق للدعم السكني
                  </label>
                  <select id="q" class="top-label-input">
                    <option>نعم</option>
                    <option>لا</option>
                    <option>لا اعلم</option>
                  </select>
                </div>
                <div class="input-container mb-5">
                  <label for="time" class="top-label">
                    {" "}
                    نطاق الراتب{" "}
                  </label>
                  <select class="top-label-input" id="time">
                    <option>من 3000 إلى6999</option>
                    <option>من7000 إلى 9999</option>
                    <option>من 10000 إلى 14000</option>
                    <option>من 14 الف فما فوق</option>
                    <option>غير ذلك</option>
                  </select>
                </div>
                <div className="mb-4">
                  <input id="keep" type="checkbox" />
                  <label htmlFor="keep" className="d-inline-block me-2 label">
                    ابقى علي تواصل بأحدث المستجدات
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn">أرسل</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-5 mb-3 mb-md-0 justify-content-start heroImg"></div>
        </div>
      </div>
      <div className="d-md-none">
        <div className="container mt-4">
          <div className="row">
            <div className="col-12">
              <h1>سجل اهتمامك</h1>
              <form className="mt-5">
                <div className="input-container mb-5">
                  <label htmlFor="name" className="top-label">
                    الاسم
                  </label>
                  <input
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    id="name"
                    type="text"
                    className="top-label-input"
                  />
                </div>

                <div className="input-container mb-5">
                  <label htmlFor="phone" className="top-label">
                    رقم الهاتف
                  </label>
                  <IntlTelInput
                    containerClassName="intl-tel-input top-label-input"
                    inputClassName="phone"
                    placeholder=""
                    defaultCountry="sa"
                  />
                </div>

                <div class="input-container mb-5">
                  <label for="q" class="top-label">
                    هل انت مستحق للدعم السكني
                  </label>
                  <select id="q" class="top-label-input ">
                    <option>نعم</option>
                    <option>لا</option>
                    <option>غير ذلك</option>
                  </select>
                </div>
                <div class="input-container mb-5">
                  <label for="time" class="top-label">
                    {" "}
                    نطاق الراتب{" "}
                  </label>
                  <select class="top-label-input " id="time">
                    <option>من 3000 إلى6999</option>
                    <option>من7000 إلى 9999</option>
                    <option>من 10000 إلى 14000</option>
                    <option>من 14 الف فما فوق</option>
                    <option>غير ذلك</option>
                  </select>
                </div>
                <div className="mb-4">
                  <input id="keep" type="checkbox" />
                  <label htmlFor="keep" className="d-inline-block me-2 label">
                    ابقى علي تواصل بأحدث المستجدات
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn">أرسل</button>
                </div>
              </form>
            </div>
            <div className="col-12 heroImg"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
